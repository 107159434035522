@import "_reset.scss";
@import "admin.scss";
@import "_ui-change.scss";


:root {

  --main-color: #0A49A5;
  --accent-color: #0A49A5;
  

  --text-color-main:#070708;
  --text-color2: #B1B1B5;
  --text-color3: #BBBEC7;
  --text-color4: #74747B;


  --bg-main: #fff;


  --border-color: #CFCFD2;
  --border-color2: #EEEEEF;

  --button-text-main: #fff;
  --button-hover-color: #0C5ACC;


  --link-color: #3B97D3;
}


html body {
  position: relative;
  // width: 1920px;
  max-width: 100%;
  margin: 0 auto;    
  font-family: 'Roboto', sans-serif ;
  color: var(--text-color-main);
  line-height: 1.6;
  font-size: 16px;
  background: #F2F2F3;
  button, input, label, textarea {
    font-family: 'Roboto', sans-serif;    
    line-height: inherit;
  }
}

.main, .main-section, .main-content {
  min-height: calc(100vh - 66px);
  width: 100%;
}

.wrapper {
  // width: 1920px;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.usn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  appearance: none;
}

section {
  box-sizing: border-box;
  padding: 1px 0;
}

button {
  margin: 0;
}

/*base*/

label {
  cursor: pointer;
}

img, svg {
  width: auto;
  max-width: 100%;
}

svg {
  display: block;
}

.header {
  height: 64px;
  gap: 16px;
  background: var(--bg-main);
  border: none;
  &--center {
    justify-content: center;
  }  
  &__logo {
    width: 130px;
    height: auto;
    background: none;
    border-radius: 0;
    @media screen and (max-width: 767px) {
      width: 100px;
    }
    img {
      border-radius: 0;
      height: auto;
    }
  }
}

.authorization-section {
  align-items: center;
  min-height: 100vh;
  padding: 50px 15px;
  background: var(--bg-main);
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.authorization-form {
  max-width: 100%;
  width: 383px;
  padding: 36px 42px;
  background: var(--bg-main);
  border: 1px solid var(--border-color2);
  border-radius: 14px;
  &__logo {
    margin-bottom: 8px;
  }
  &__body {
    margin-top: 24px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 344px;
  }

  @media screen and (max-width: 360px) {
    padding: 20px 5px;
    width: 100%;
  }
}

.input-notification {
  margin-top: 4px;
  color: var(--text-color2);
  font-weight: 500;
  font-size: 11px;
  &__link {
    display: block;
    text-align: right;
    color: #3B97D3;
  }
}

.form-title {
  font-weight: 500;   
  font-size: 20px; 
}

.cred-remember-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  color: var(--link-color);
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  &--type2 {
    color: var(--main-color);
  }
  &--ml-12 {
    margin-left: 12px;
  }
}

.main-content {
  //max-width: 1180px;
  width: unset;
  flex: 1;
  padding: 34px 16px 16px 16px;
  background: var(--bg-color-main);
  line-height: 1.8;

  &--type2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #FCF5EB 26.3%, #BDE0F6 70.64%);
  }
}

.main-section {
  // min-height: calc(100vh - 64px);
  padding: 0;
}

.sidebar {
  background: var(--bg-main);
  border-right: 1px solid var(--border-main-color);
  &__action {
    flex: 0 0 24px;
    max-width: 24px;
  }
  &--hidden {
    width: 76px;
    .sidebar-nav-text {
      white-space: nowrap;
      font-size: 0;
    }
    .sidebar-nav__list {
      >li {
        >a, >button {
          justify-content: center;
          padding: 15px 16px;
          gap: 0;
          
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    width: 220px;
  }
  @media screen and (max-width: 991px) {
    .sidebar-nav-text {
      white-space: unset;
      font-size: 14px;
    }
    .sidebar-nav__list {
      >li {
        >a, >button {
          justify-content: flex-start !important;          
          gap: 16px;
        }
      }
    }
    &.active {
      display: block;
    }
  }  
}

.sidebar-nav {
  &__list {
    > li {
      > a, button, ul > li >a, ul >li >button {
        display: flex;
        width: 100%;
        gap: 16px;
        padding: 15px 16px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        color: var(--text-color-main);
        transition: all 0.2s ease;
        svg path {
          stroke: var(--text-color-main);
          transition: 0.3s;          
        }
        &:hover, &.active {
          color: #fff; 
          background: var(--main-color);
          svg path {
            stroke: #fff;
          }
        }
      }
    }
  }
}

.sidebar-nav-icon {
  flex: 0 0 15px;
  transition: all 0.2s ease;
}

.sidebar-nav-text {
  transition: all 0.2s ease;
}

.admin {
  margin-right: 4px;
  color: var(--text-color-main);
}


.table {
  &--channels-all {
    .tr {
      grid-template-columns: minmax(60px, 0.6fr)  1.1fr 0.8fr 1.1fr .6fr 1fr 0.8fr 0.8fr 0.8fr 0.5fr minmax(60px, 0.8fr) minmax(60px, 0.8fr);
    }
  }
  &--channels-recommended {
    .tr {
      grid-template-columns: 0.4fr minmax(60px, 0.6fr)  1.1fr 0.8fr 1.1fr .6fr 1fr 0.8fr 0.8fr 0.8fr 0.5fr minmax(60px, 0.8fr) minmax(60px, 0.8fr);
    }
  }
  &--ai-recommended {
    .tr {
      grid-template-columns: 0.4fr minmax(60px, 0.6fr)  1fr 0.8fr 0.8fr .4fr 0.4fr 0.4fr;
    }
  }
  &--list-codes {
    .tr {
      grid-template-columns: 1fr 0.6fr 0.8fr .6fr 0.6fr 0.6fr 1fr;
    }
  }

  &--info-codes {
    .tr {
      grid-template-columns: 1fr 0.8fr 0.8fr 0.8fr 0.4fr;
    }
  }
  &--withdrawal-all {
    .tr {
      grid-template-columns: 1fr  1fr 0.8fr 1.1fr .9fr 0.8fr 0.8fr 0.8fr 0.8fr 0.7fr minmax(60px, 0.5fr);
    }
  }

  &--statistics-systemic {
    .tr {
      grid-template-columns: 0.8fr  0.8fr 1fr 1fr 1fr 0.8fr 0.8fr 0.8fr 0.8fr 0.6fr;
    }
  }

  &--statistics-customers {
    .tr {
      grid-template-columns: 0.8fr  0.8fr 1fr 1fr 1fr 0.8fr 0.8fr 0.8fr 0.6fr;
    }
  }

  &--track-customers {
    .tr {
      grid-template-columns: 0.8fr 1fr 0.8fr 0.8fr 0.8fr 0.6fr 1fr;
    }
  }

  &--album-customers {
    .tr {
      grid-template-columns: 0.8fr 1fr 0.8fr 0.8fr 0.8fr 0.6fr 1fr 1fr;
    }
  }

  &--subscribers-customers {
    .tr {
      grid-template-columns: 0.4fr  1fr 1fr 0.5fr 0.8fr 0.8fr 0.8fr 0.6fr 0.4fr;
    }
  }

  &--subscribers-customers-ui {
    .tr {
      grid-template-columns: 0.4fr  1fr 0.5fr 0.8fr 0.8fr 0.8fr 0.6fr 0.4fr;
    }
  }

  &--reserved {
    .tr {
      grid-template-columns: 1fr  1fr 1fr 0.8fr 0.8fr 0.8fr 0.6fr 0.4fr;
    }
  }

  &--usernames {
    .tr {
      grid-template-columns: 0.7fr  0.7fr 1fr 120px 60px;
    }
  }

  
  &--terms {
    .tr {
      grid-template-columns: 1fr 1fr 0.7fr 0.8fr;
    }
  }

  &--withdrawal-all-crypto {
    .tr {
      grid-template-columns: 1fr  1fr 0.8fr 0.5fr .5fr 0.8fr 0.8fr 0.8fr 0.8fr 1fr minmax(60px, 0.5fr);
    }
  }
  &--withdrawal-all-balances {
    .tr {
      grid-template-columns: 0.8fr  0.8fr 0.5fr 0.5fr .5fr 0.5fr 1fr 1fr 1fr 0.6fr minmax(60px, 0.5fr);
    }
  }
  &--channels-blocked {
    .tr {
      //grid-template-columns: minmax(140px, 0.6fr)  1.1fr 1.2fr 1.1fr 1fr 70px;
      grid-template-columns: minmax(140px, 0.6fr)  1fr 1.2fr 1.1fr .6fr 1fr 70px;
    }
  }
  &--channels-single {
    .tr {
      grid-template-columns: 1fr 1fr 0.8fr 0.6fr;
    }
  }
  &--content-photo {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--content-video {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--content-audio {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--content-voice {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--content-files {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--content-links {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--transactions-withdrawals{
    .tr {
      grid-template-columns: 1fr 1fr 1fr 1.2fr 1fr 1fr 1fr .5fr;
    }
  }

  &--ai-income {
    .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  &--transactions-withdrawals-channels {
    .tr {
      grid-template-columns: 1fr .8fr .5fr;
    }
  }
  &--admin-treasury {
    .tr {
      grid-template-columns: 1fr .8fr .5fr;
    }
  }
  &--transaction-history {
    .tr {
      grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr 1fr .5fr;
    }
  }
  &--reports-channels {
    .tr {
      grid-template-columns: minmax(86px, .7fr) .8fr .9fr .9fr .62fr minmax(70px, .62fr) .99fr .87fr 1fr 0.9fr .55fr;
    }
  }
  &--reports-posts {
    .tr {
      grid-template-columns: minmax(55px,.63fr) 0.9fr .60fr .98fr .99fr .65fr .97fr 1.07fr 0.85fr 0.9fr .80fr .95fr minmax(85px,.85fr);
      // grid-template-columns: 73px 92px 60px 98px 99px 70px 85px 97px 110px 97px 80px 95px 85px;   
      padding: 0 12px;   
      @media screen and (max-width: 1199px) {
        padding: 0;
      }
      
    }
    .td {
      padding: 6px 3px;
      @media screen and (max-width: 1199px) {
        padding: 12px 15px;
      }
    }
  }
  &--users-registered-personal {
    .tr {
      //grid-template-columns: .8fr .7fr .4fr .85fr .8fr .6fr .83fr .9fr .5fr .5fr .5fr .35fr;
      grid-template-columns: minmax(40px, 60px) 0.8fr 0.8fr .9fr .5fr .5fr 0.7fr .4fr .4fr .4fr .35fr;
      @media screen and (max-width: 1300px) {
        grid-template-columns: minmax(40px, 60px) 0.8fr 0.8fr .9fr .5fr .5fr 0.7fr .4fr .4fr .4fr .35fr;
      }
    }
    .table-body {
      .td {
        &:first-child {
          word-break: break-all;
        }
        &:nth-child(3) {
          word-break: break-all;
        }
      }
    }
  }
  &--refund {
    .tr {
      grid-template-columns: minmax(40px, 60px) 0.8fr 0.8fr .9fr .5fr .5fr;
    }
  }
  &--users-busines-personal{
    .tr {
      grid-template-columns: .9fr .35fr .85fr .85fr .85fr .8fr 1fr .5fr .5fr .5fr .35fr;
    }
  }
  &--users-registered-limited {
    .tr {
      grid-template-columns: .9fr 0.35fr 1fr .9fr .9fr 0.75fr 0.8fr .55fr .55fr .6fr 0.5fr 60px;
    }
  
}

  &--users-private-chats {
    .tr {
      grid-template-columns: .5fr 1fr 1fr 1fr 0.7fr 0.7fr .7fr .5fr;
    }
  }
  &--channels-private-admins {
    .tr {
      grid-template-columns: .5fr 1fr 1fr 0.6fr 0.7fr 0.8fr 0.8fr;
    }
  }

  &--channels-private-media {
    .tr {
      grid-template-columns: 1fr 0.6fr 0.6fr;
    }
  }

  &--channels-private-media-link {
    .tr {
      grid-template-columns: 1fr 0.6fr 0.6fr 0.6fr;
    }
  }
  &--channels-private-post {
    .tr {
      grid-template-columns: 85px 1.1fr 0.8fr 0.4fr 0.7fr;
    }
  }
  &--admin-rules {
    .tr {
      grid-template-columns: 1fr 1fr 0.7fr 0.7fr 0.5fr;
    }
  }
  &--channels-private-media-sales {
    .tr {
      grid-template-columns: .5fr 1fr 1fr 0.6fr 0.7fr 0.8fr 0.8fr 0.8fr;
    }
  }
  &--users-private-groups {
    .tr {
      grid-template-columns: .5fr 1fr 1fr 1fr 1fr 0.4fr 0.7fr;
    }
  }

  &--users-partners {
    .tr {
      grid-template-columns: .4fr 1fr 1fr 1fr 1fr 0.7fr;
    }
  }
  &--users-private-reports {
    .tr {
      grid-template-columns: .5fr 1fr 1fr 1fr 1fr 0.6fr 0.7fr;
    }
  }
  &--users-transaction-history {
    .tr {
      grid-template-columns: .8fr .8fr 1fr 1fr .7fr .7fr;
    }
  }
  &--partners-management {
    .tr {
      grid-template-columns: 1.2fr 1.2fr 1fr 0.8fr .7fr .7fr;
    }
  }
  &--partners-request {
    .tr {
      grid-template-columns: .6fr 1.2fr 1.2fr 0.8fr .7fr .7fr;
    }
  }
  &--users-free-channels {
    .tr {
      grid-template-columns: .8fr 1fr 1fr .7fr .7fr;
    }
  }
  &--conversations-channels {
    .tr {
      grid-template-columns: .5fr 1fr 1fr 1fr .7fr .7fr;
    }
  }
  &--users-violations {
    .tr {
      grid-template-columns: 1fr 0.5fr 0.5fr 0.8fr 1.2fr 60px;
    }
  }
  &--ai-bot {
    .tr {
      grid-template-columns: 1fr 0.5fr 0.5fr 1.1fr 0.8fr 150px;
    }
  }
  &--conversations-group {
    .tr {
      grid-template-columns: .8fr 1fr 1fr .7fr .7fr;
    }
  }
  &--conversations-chats {
    .tr {
      grid-template-columns: .8fr 1fr 1fr .9fr .7fr;
    }
  }
  &--users-list-post {
    .tr {
      grid-template-columns: 1fr 1fr 0.8fr 0.6fr;
    }
  }
  &--urers-content-photo {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--users-content-video {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--users-content-audio {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--users-content-voice {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--users-content-files {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }
  &--users-content-links {
    .tr {
      grid-template-columns: 1.6fr 1fr 1fr;
    }
  }

  .td {
    &--fw-500 {
      font-weight: 500;
    }
  }
}



.sort-td {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  width: 10px;
}

.table-buttons {
  button {
    flex: 0 0 18px;
    max-width: 18px;
  }
  .button {
    max-width: unset;
    flex: unset;
    &+button {
      margin-left: 16px;
    }
  }
  button.button--td-action {
    flex: 0 0 128px;
    max-width: 128px;
  }
}

.btn-icon {
  display: flex;
  justify-content: center;
  width: 15px;  
  svg {
    min-width: unset;
  }
}


.tabs-buttons-nav {
  display: flex;
  gap: 8px;
  margin-top: 14px;
  >button {
    position: relative;
    min-width: 148px;
    padding: 0 5px;
    padding: 16px 12px;
    border-radius: 8px 8px 0px 0px;    
    font-size: 16px;    
    &:first-child {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 6px;height: 6px;
        background: #fff;
        display: none;
      }
      &.active {
        &:after {
          display: block;
        }
      }
    }
    &.active {
      background: #fff;
      font-weight: 500;
    }

  }

  @media screen and (max-width: 520px) {
    flex-wrap: wrap;
    margin-bottom: 16px;
    gap: 16px;
    //background-color: rgba(10, 73, 165, 0.1);
    //border-radius: 8px;
    //padding: 16px;
    >button {
      border-radius: 8px;
      padding: 10px;
      background-color: #fff;
      &.active {
        background: var(--main-color);
        color: #fff;
      }
      &:first-child.active:after {
        display: none;
      }
    }
  }
}

.title-block {  
  gap: 16px;
  @media screen and (max-width: 720px) {
    width: 100%;
    
  }    
  &--space-between {
    justify-content: space-between;
  }
}


.title {
  font-size: 28px;
}

.block-title {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
}

.popup-buttons {
  display: flex;
  margin-top: 24px;
  gap: 16px;
  &--column {
    flex-direction: column;
    align-items: center;
  }
}

.back-button {
  display: flex;
  width: 24px;

  svg {
    min-width: 24px;
    min-height: 24px;
  }
}

.table-tabs {
  display: flex;
  gap: 8px;
  padding: 16px 16px 0 16px;
  // border-bottom: 1px solid #CFCFD2;
  &__item {
    position: relative;
    padding: 0 12px 20px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: var(--text-color4);

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 12px;
      display: none;
      width: calc(100% - 24px);
      height: 3px;
      background-color: #0A49A5;
    }

    &--bigger {
      &::before {
        width: 112px;
      }
    }

    &.active {
      font-weight: 500;
      color: var(--main-color);
      &::before {
        display: block;
      }
    }
  }
  // &--type2 {
  //   // .table-tabs__item {
  //   //   //padding: 16px 12px 24px 12px;
  //   // }
  // }

  @media screen and (max-width: 720px) {
    flex-wrap: wrap;
  }


}

.avatar-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.popup-galery {
  height: 276px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding-right: 8px;  
  width: calc(100% + 8px);
  &::-webkit-scrollbar {
    width: 7px;
    margin-left: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(207, 207, 210, 0.5);
    border-radius: 50px;
  }
  &--flex {
    display: flex;
    height: auto;
    flex-wrap: wrap;
  }
  &--padding24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  &--mb16 {
    margin-bottom: 16px;
  }
  @media screen and (max-width: 675px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.popup-galery-item {
  display: flex;
  height: 130px;
  //width: 130px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.popup-galery-wrapper {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #EEEEEF;
}

.popup-galery-icon {
  display: flex;
  width: 34px;
  margin-bottom: 8px;
}

.popup-galery-name {
  width: 109px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: var(--text-color-main);
}

.popup-galery-text {
  font-weight: 400;
  font-size: 12px;
  color: var(--text-color4);
}
.table-content {
  display: flex;
  align-items: center;
  gap: 8px;
  &__download-btn {
    display: flex;
    width: 24px;
  }
  &__name {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: var(--text-color-main);
  }
  &__size {
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: var(--text-color4);
  }
}

.btn-reletive {
  position: relative;
  // overflow: hidden;
  &:hover {
    .button-tooltip  {
      transform: translate(-50%, 0);
      visibility: visible;
       opacity: 1;
    }
  }
}

.button-tooltip {
  position: absolute;
  left: 50%;
  bottom: -45px;
  transform: translate(-50%, 100%);
  display: inline-block;
  padding: 4px 8px;
  background: rgba(37, 37, 37, 0.8);
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 20;

  &--right {
    position: relative;
    left: 120%;
    width: max-content;
    max-width: 200px;
    bottom: -25px;
  }
}

.reletive-block {
  position: relative;

  &:hover {
    .button-tooltip  {
      transform: translate(-50%, 0);
      visibility: visible;
       opacity: 1;
    }
  }
}

.secret-key {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__icon {
    display: flex;
    width: 45px;
    margin-bottom: 8px;
  }
  &__title {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
  }
  .input {
    margin: 0;
  }
}

.popop-error {
  position: relative;
  top: 22%;
  width: 325px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  background: #FDE7EB;
  border-radius: 12px;
  &__icon {
    display: flex;
    width: 24px;
  }
  &__text {
    font-weight: 400;
    font-size: 16px;
    color: var(--text-color-main);
  }
}

.popup-text {
  p:not(:last-child) {
    margin-bottom: 0;
  }
}

.popup-posts-list {
  height: 364px;
  overflow-y: auto;
  border-top: 1px solid #CFCFD2;
  &::-webkit-scrollbar {
      width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(207, 207, 210, 0.5);
    border-radius: 50px;
  }
  .popup-text {
    margin-bottom: 4px;
  }
}

.popup-posts-item {
  padding: 16px 24px;
  border-bottom: 1px solid #CFCFD2;
  background: #F7F7F7;
}

.vertified-butons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
  }
}

.vertified-btn {
  display: flex;
  width: 18px;
  min-width: 18px;
}


.join-block {
  max-width: 449px;
  width: 100%;
  padding: 32px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  background: #FFFFFF;
  &__logo {
    width: 100px;
    height: 100px;
    margin: 0 auto 16px;
  }
  &__text {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #070708;
    text-align: center;
    &--mb0 {
      margin-bottom: 0;
    }
  }
  &__button {
    display: block;
    margin: 16px auto 0;
  }
}

.completed {
  color: #27AE60;
}

.rejected {
  color: #EF4062;
}

.waiting {
  color: #F2994A;
}

.admin_check {
  color: #f2e44a;
}

.in_progress {
  color: #070708;
}


.blue {
  color: #0A49A5 !important;
}

.green {
  color: #27AE60 !important;
}

.orange {
  color: #E0A147 !important;
}

.error-red {
  color: #EF4062 !important;
}

.red {
  color: #E04141 !important;
}

.orange {
  color: #F2994A !important;
}
.b-yellow {
  background-color: yellow;
}

@import "ui-modifiers.scss";

.select {
  position: relative;
  display: flex;
  align-content: center;
  background: transparent;

  &__current {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 16px;
      padding: 0 16px;
      font-size: 16px;
      color: var(--text-color-main);
      font-weight: 700;
  }

  &__current-required {
      color: var(--text-color-placeholder);
  }

  &__current-arrow {
      display: flex;
      flex: 0 0 13px;
      max-width: 13px;
      margin-left: auto;
      svg {
          position: relative;
          transform-origin: 50% 50%;
          transition: all 0.2s linear;
      }
  }

  &__current-text {
      margin-right: auto;
  }

  &__drop {
      display: none;
      position: absolute;
      left: 0;
      top: calc(100% + 10px);
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #CFCFD2;
      /* Drop_shadow_light */
      
      box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
      border-radius: 14px;
      z-index: 1;

      ul {
          > li {
              > a,
              > button {
                  display: flex;
                  align-items: center;
                  min-height: 50px;
                  gap: 8px;
                  width: 100%;
                  padding: 8px 16px;
                  color: var(--text-color-main);
                  font-weight: 700;
                  font-size: 14px;
                  transition: all 0.2s linear;
                  text-align: left;

                  &:hover {
                      background: var(--background-third);
                  }
              }
          }
      }
  }

  &__drop-scroll {
      max-height: 250px;
      border-radius: 12px;
      overflow: auto;
      scrollbar-color: #b6b6b6 transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
      }

      &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background: var(--background-third);
      }
  }

  &.active {
      z-index: 5;
      border-color: var(--main-color);
      .select__current {
          border-color: var(--main-color);
      }
      .select__current-arrow svg {
          transform: rotate(-180deg);
      }
      .select__drop {
          display: block;
      }
  }
  &--input-type {
      .input-item {
          border: none;
          padding: 0;
      }
      .select__current-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
      }
  }
}

.not-background {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #CFCFD2 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.td-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}

.edit-main__input-wrp .input-item--left-icon-none {
  font-size: 16px !important;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  line-height: inherit;
}

.louder-small-wrapper-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.button-select {
  display: flex;
  justify-content: space-between;
  padding: 9px 12px 9px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #CFCFD2;
  width: 100%;
  margin-bottom: 10px;
}

.button-label {
  color: #070708;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  margin-bottom: 8px;
}
