.structure {
  display: grid;
  gap: 16px;

  &__box {
    width: 100%;
    padding: 16px 30px;
    align-self: stretch;
    border-radius: 15px;
    background: #F7F7F8;
    gap: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    cursor: pointer;
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      color: var(--Color-Text-Dark, #070708);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }
  }

  &__line {
    display: flex;
    flex-direction: column;
    gap: 3px;

    span {
      height: 1px;
      width: 15px;
      background: #070708;
    }
  }
}

.accordion {
  position: relative;
  width: 100%;
  border-top: 1px solid #D9D9D9;
  padding-top: 16px;
  margin-top: 16px;

  &__delete {
    position: absolute;
    bottom: 0;
    border: 1px solid #CFCFD2;
    border-radius: 12px;
    padding: 4px 10px;
    color: red;
  }

  &__save {
    position: absolute;
    bottom: 0;
    left: 80px;
    border: 1px solid #007aff;
    border-radius: 12px;
    padding: 4px 10px;
    color: #007aff;
  }

  &__save:disabled {
    background-color: #d3d3d3; /* Сірий колір */
    color: #ffffff;
    border: 1px solid #d3d3d3;
    cursor: not-allowed; /* Змінює курсор на заборонений знак */
    opacity: 0.6; /* Робить кнопку трохи прозорішою */
  }

  &__box {
    display: grid;
    grid-template-columns: 0.8fr 1fr 1fr;
    width: 100%;
    gap: 16px;
    margin-bottom: 8px;
  }

  &__box-text {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    width: 100%;
    gap: 16px;
    margin-bottom: 8px;
  }

  &__box-2 {
    grid-template-columns: 1fr 1fr;

    @media (max-width: 642px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__left-content {
    color: var(--Text-Primary_Light, #070708);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }

  &__label {
    display: flex;
    position: relative;
    flex-direction: column;
    color: var(--Text-Primary_Light, #070708);
    /* Regular/P3 */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */

    span {
      position: absolute;
      right: 5px;
      top: calc(50%);
    }
  }

  &__input {
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;
    padding: 12px;
  }

  &__right-content {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
}

.accordion__right-content .background__wrapper-drop {
  width: 100%;
}

.background__textarea-wrap {
  width: 100%;
}

.reactions-add__input-wrapper {
  width: 100%;
}