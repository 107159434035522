.suport {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  background: #FFFFFF;

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #070708;
  }

  &__header {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 360px 1fr 320px;
    width: 100%;
    height: 100%;
  }

  &__header-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 19px;
    background: #FFFFFF;

    filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.04));
    // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.04);
    padding: 0 19px;
  }

  &__header-left-between {
    display: flex;
    align-items: center;
    gap: 19px;
  }

  &__btn-back {
    cursor: pointer;
  }

  &__header-center {
    position: relative;
    display: flex;
    align-items: center;
    gap: 19px;
    justify-content: space-between;
    width: 100%;
    // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.04);
    padding: 0 25px;
  }

  // &__input-wrap {
  //   position: relative;
  //   width: 18px;
  // }

  &__input {
    position: absolute;
    left: 16px;
    top: calc(50% - 20px);
    width: 100%;
    max-width: calc(1606px - 40px);
    background: #EEEEEF;
    border-radius: 18px;
    transition: all 0.5s;
  }

  &__input-opacity {
    width: 0;
    overflow: hidden;
  }

  &__input-item {
    background: #EEEEEF;
    border: none;
  }

  &__tab-wrapper {
    display: flex;
    gap: 37px;
    align-items: center;
  }

  &__tab {
    color: #929298;
    transition: height 1s;

    &:hover {
      color: #0A49A5;
      cursor: pointer;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -21px;
        left: 0;
        width: 100%;
        height: 4px;
        background: #0A49A5;
        border-radius: 6px 6px 0px 0px;
      }
    }
  }

  &__header-right {
    display: flex;
    align-items: center;
    gap: 19px;
    justify-content: space-between;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
    padding: 0 19px;
  }

  &__flex {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__blue {
    color: #0A49A5;
  }
}

.chat {
  padding: 16px 20px 22px;
  width: 100%;

  overflow-x: auto;
  width: 100%;
  height: calc(100vh - 80px);
  // transform: rotate(180deg);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }

  // &::-webkit-scrollbar-track {
  //   background: #B1B1B5;
  //   border-radius: 12px;
  // }

  &::-webkit-scrollbar-thumb {
    background: #B1B1B5;
    border-radius: 30px;
    // border: 3px solid orange;  /* отступ вокруг бегунка */
  }
  // , 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 12, 13

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.20);
    margin-bottom: 8px;
  }

  &__arrow-wrap {
    display: flex;
    gap: 12px;
    min-width: 130px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: -6px;
    height: calc(100% - 215px);
    overflow: visible;
  }

  &__content-header {
    display: flex;
    gap: 10px;
    padding: 16px;
    border-bottom: 1px solid var(--color-border-light-grey, #F6F6F6);
    background: #FFF;
  }

  &__select-left {
    width: 100%;
  }

  &__select-right {
    min-width: 180px;
  }

  &__content-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 16px;
    height: 100%;
    min-height: 100%;
    background: url(../../images/background.png);
    background-size: contain;

    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
  
    // &::-webkit-scrollbar-track {
    //   background: #B1B1B5;
    //   border-radius: 12px;
    // }
  
    &::-webkit-scrollbar-thumb {
      background: #B1B1B5;
      border-radius: 30px;
      // border: 3px solid orange;  /* отступ вокруг бегунка */
    }
  }

  &__content-footer {
    padding: 16px;
    border-bottom: 1px solid var(--color-border-light-grey, #F6F6F6);
    background: #FFF;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    height: max-content;
    margin-bottom: 25px;
  }

  &__input-text-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__input-text {
    width: 100%;
    height: 50px;
    border-radius: 12px;
    border: 1px solid var(--color-divider-grey);
    background: #FFF;
    padding: 0 50px;
  }

  &__input-svg-smile {
    position: absolute;
    left: 20px;
    top: calc(50% - 14px);
  }

  &__input-svg-clip {
    position: absolute;
    right: 20px;
    top: calc(50% - 14px);
    overflow: hidden;

    label {
      position: absolute;
      opacity: 0;
    }
  }
}

.chat__box .users__ava-wrap img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.status-width {
  width: 55px;
}

.line {
  border-right: 1px solid #F2F2F2;
  padding-right: 12px;
}

.chat__arrow-wrap .pointer {
  display: flex;
  align-items: center;
}

.content-activ {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.height-max {
  height: 100%;
}

.hidden {
  height: 0;
  overflow: hidden;
}

.select__svg {
  display: flex;
  gap: 8px;
}

.chat__select-right .countries-list svg {
  margin-left: 0;
}

.input-padding-left {
  padding-left: 40px;
}

.grid-template-two-columns {
  grid-template-columns: 360px 1fr;
}

.chats-background {
  width: 100%;
  min-height: calc(100vh - 66px);
  background: url(../../images/background.png);
  background-size: cover;
}

.chat-list {
  display: flex;
  gap: 10px;

  &--right {
    justify-content: flex-end;
  }

  &__img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    align-self: flex-end;
  }

  &__text-wrap {
    position: relative;
    border-radius: 12px;
    background: var(--color-text-white);
    padding: 8px;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 350px;

    &--right {
      background-color: var(--ui-checkbox-bg);
      color: var(--color-text-white);
    }

    img {
      border-radius: 6px;
    }
  }

  &__corner {
    position: absolute;
    bottom: 0;
    left: -8px;

    &--right {
      left: auto;
      right: -8px !important;
    }
  }
}

.show {
  grid-template-columns: 360px 1fr;
}

.suport__header-grid-two-column-left {
  transition: all 0.4s;
  grid-template-columns: 65px 1fr;
}

.suport__header-grid-left {
  transition: all 0.4s;
  grid-template-columns: 65px 1fr 320px;
}

.suport__header-grid-right {
  transition: all 0.4s;
  grid-template-columns: 65px 1fr 320px;
}

.suport__header-grid-right-left-sidebar {
  transition: all 0.4s;
  grid-template-columns: 65px 1fr 65px;
}

.suport__header-grid-right-sidebar {
  transition: all 0.4s;
  grid-template-columns: 360px 1fr 65px;
}

.suport__header-right-heid {
  box-shadow: none;
}

.chat__input-svg-smile > div {
  bottom: 45px;
  position: absolute;
}

.active__tab {
  color: #929298;
  transition: height 1s;
  color: #0A49A5;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -21px;
    left: 0;
    width: 100%;
    height: 4px;
    background: #0A49A5;
    border-radius: 6px 6px 0px 0px;
  }
  
}

.wrapper-support {
  min-width: 1024px;
}

.chat-list-avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
}

.text-color-admin {
  a {
    color: rgb(255, 255, 255);
  }
}
