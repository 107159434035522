.ai-models {
  padding: 24px 15px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__add-model-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;

    height: 44px;
    padding: 10px 16px;

    color: #0A49A5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 25.2px */
  }
}

.ai-models {
  .accordion {
    border: none;
    border-radius: 15px;
    background: #F7F7F8;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    padding: 16px 30px;

    &-header {
      background-color: #f7f7f7;
      cursor: pointer;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .chevron {
        transition: transform 0.3s ease;

        &.open {
          transform: rotate(90deg);
        }
      }
    }

    &-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease, padding 0.3s ease;
      padding: 0 15px;

      &.open {
        max-height: 500px; // Задати максимальну висоту для розкритого контенту
        padding: 16px 0;
      }
    }
  }
}

.accordion-input-container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #D9D9D9;
  gap: 16px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #EF4062;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    margin: 16px 0 0;
    align-self: flex-end;
  }
}

.accordion-input-wrap {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  align-items: center;

  span {
    color: #070708;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
  }

  input, select {
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;
    padding: 8px 12px 7px 12px;
  }

  select {
    padding: 12px;
  }

  textarea {
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;
    padding: 12px;
  }
}

.accordion-buttons {
  display: flex;
  gap: 8px;
  margin-top: 16px;

  button {
    &:first-child {
      border-radius: 12px;
      border: 1px solid #CFCFD2;
      background: #FFF;
      padding: 10px 16px;
      color: #0A49A5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 180%; /* 25.2px */
    }

    &:last-child {
      border-radius: 12px;
      background:#0A49A5;
      padding: 10px 16px;
      color: #FFF;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 180%; /* 25.2px */
    }
  }
}

.custom-select {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 11px;
  background-color: #fff;
  cursor: pointer;
}

.selected-option {
  display: flex;
  align-items: center;
  padding: 8px;
  border: none;
  transition: background-color 0.3s;
}

.option-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.option-content img {
  height: 20px;
  object-fit: contain;
}

.options {
  display: none; /* Початково прихований список */
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 12px;
  border: 1px solid #CFCFD2;
  background: #FFF;
  padding: 12px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto; /* Прокрутка для довгих списків */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease;
  height: 200px;
}

.options.open {
  display: block;
}

.option {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 8px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #CFCFD2;
  }
}

.option img {
  height: 20px;
  object-fit: contain;
}

.option span {
  font-size: 14px;
  color: #333;
  flex-grow: 1;
}

/* Стилі для прокрутки */
.options::-webkit-scrollbar {
  width: 8px;
}

.options::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.options::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.options::-webkit-scrollbar-track {
  background-color: #f9f9f9;
  border-radius: 4px;
}

.cancel-button:disabled {
  background-color: #d3d3d3; // Колір для неактивної кнопки
  color: #9e9e9e;
  cursor: not-allowed;
  box-shadow: none;
}
