
.add-new-template {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(177, 177, 181, 0.72);
  padding: 24px 24px 32px 24px;
  backdrop-filter: blur(4px);

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .add-new-template-popup {
    position: relative;
    max-width: 550px;
    width: 100%;
    padding: 40px;
    gap: 10px;
    margin: 20px 10px;
    max-height: 90vh;
    overflow-y: auto;


    border-radius: 10px;
    background: var(--BG-Primary, #FFF);
    box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.08);

    &__title {
      color: var(--Text-Primary_Light, #070708);
      text-align: center;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }

    &__lable {
      color: var(--Text-Primary_Light, #070708);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
      margin: 16px 0 5px;
    }

    &__editor {
      .ql-container {
        height: auto !important;
      }
    }

    .background__wrapper-drop {
      grid-template-columns: 1fr;
    }

    &__btn-wrap {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 24px;
    }

    &__btn-approve {
      border-radius: 18px;
      padding: 10px 16px;
      color: #FFF;
      background: var(--main-color);

      /* Medium/P3 */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 180%; /* 28.8px */

      &:disabled {
        background: var(--main-color-active);
        cursor: not-allowed; // Вказівник для недоступних елементів
      }
    }

    &__btn-reject {
      border-radius: 18px;
      border: 1px solid var(--Line-Line_Light, #CFCFD2);
      background: var(--bg-accent-1-light, #FFF);
      padding: 10px 16px;
      color: var(--Text-Link, #0A49A5);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 180%; /* 28.8px */
    }
  }
}

.timezone-filter {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr auto;
  gap: 0.5rem;
  align-items: center;

  .end-date {
    grid-column: 1 / 1;
    grid-row: 2;
  }

  .end-time {
    grid-column: 2 / 2;
    grid-row: 2;
  }

  // Common styles for each input container
  .date-picker-wrap {
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;
    padding: 6px 12px 5px 12px;
    // Remove any extra styling that may add another border
    .react-datepicker__input-container input {
      border: none;
      outline: none;
      width: 100%;
      color: #333;
      background-color: transparent;
      font-size: 1rem;
      padding-right: 1.5rem; // Leave space for the icon
    }

    // Icon styling
    .icon {
      position: absolute;
      right: 0.75rem;
      color: #007bff;
      font-size: 1.2rem;
      pointer-events: none; // Prevents interaction with the icon
    }
  }

  // Styles for timezone select dropdown
  select {
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;
    padding: 8px 12px;
    font-size: 1rem;
    color: #333;
    cursor: pointer;
    height: 100%;
    grid-column: 3 / 3;
    grid-row: 2;
  }

  ::placeholder {
    color: #a8a8a8;
  }
}

.timezone-filter .react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  position: relative;
  z-index: 5 !important;
}

.react-datepicker {
  color: #070708 !important;
  padding: 0 !important;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: none !important;
  padding: 5px 0 !important;
}

.react-datepicker__time-container {
  padding-bottom: 11px;
}

.image-preview {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.btn-remove {
  position: absolute;
  top: 5px;
  right: 0;
}

.image-preview-wrap {
  button {
    z-index: 7;
    .ava {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  video {
    width: 100%; /* Контейнер заповнює всю ширину */
    max-width: 800px; /* Обмеження максимальної ширини */
    aspect-ratio: 16 / 9; /* Встановлює співвідношення сторін */
    overflow: hidden; /* Запобігає виходу відео за межі контейнера */
  }
}

.ava {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.drop-search {
  position: relative;
  grid-column: 3 / 3;
  grid-row: 2;
  height: 100%;
  width: 190px;

  input {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;
    padding: 6px 25px 5px 12px;
    width: 100%;
  }

  div {
    display: none;
    position: absolute;
    z-index: 5;
    left: 0;
    top: calc(100% + 10px);
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #CFCFD2;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
    border-radius: 14px;
  }

  ul {
    max-height: 260px;
    border-radius: 12px;
    overflow: auto;
    scrollbar-color: #b6b6b6 transparent;
    scrollbar-width: thin;

    li {
      padding: 5px 10px;
      &:hover {
        background-color: #216ba5;
        color: white;
        font-weight: bold;
      }
    }
  }
}

.open-drop {
  display: block !important;
}

.pointer-events {
  position: absolute;
  pointer-events: none;
  right: 5px;
  top: 11px;;
}

.wrap-content {
  position: relative;
  display: flex;
  align-items: flex-end;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(124, 124, 234, 0.5);
  }
}
