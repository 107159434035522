.recommendations {
  .no-recommendations {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 80px 10px;
  }
}

.btn-add-recommendation {
  position: relative;
  bottom: -90px;
  left: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  max-width: 180px;

  padding: 10px 16px;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  border: 1px solid var(--Line-Line_Light, #CFCFD2);
  background: var(--bg-accent-1-light, #FFF);

  color: var(--Text-Link, #0A49A5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 25.2px */
}

.ql-editor, .ql-toolbar, .background__inner-drop {
  // border: 1px solid #CFCFD2;
  background: #FFF;
}

.frequency-title {
  color: var(--Color-Text-Dark, #070708);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.frequency-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--Text-Primary_Light, #070708);
  text-align: right;

  /* Regular/P3 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */

  input {
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;
    padding: 8px 12px;
  }

  select {
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;
    padding: 8px 12px;
    font-size: 1rem;
    color: #333;
    cursor: pointer;
    height: 100%;
    grid-column: 3 / 3;
    grid-row: 2;
    min-width: 150px;
  }
}

.button-delete {
  color: #000;
  border-radius: 8px;
  background-color: rgb(255, 0, 0, 0.5);
  padding: 2px 5px;
}

.button-recomendation-save {
  color: #fff;
  border-radius: 8px;
  background-color: rgba(26, 66, 243, 0.705);
  padding: 2px 12px;
  
  &:disabled {
    background: var(--main-color-active);
    cursor: not-allowed; // Вказівник для недоступних елементів
  }
}

.button-delete-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
