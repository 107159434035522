
.photo-wrap {
  img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
}

.video__container {
  position: relative;
  width: 100%;
  max-height: 300px;
  aspect-ratio: 16 / 9;
}

.video__container video {
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}