.edit-bot {
  padding: 24px 15px;
}

.title-block-wrap {
  // display: flex;
  // justify-content: space-between;
}

.button-save {
  background: var(--main-color);
  color: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  border-radius: 18px;
  padding: 10px 16px;

  &:disabled {
    background: var(--main-color-active);
    cursor: not-allowed; // Вказівник для недоступних елементів
  }
}
