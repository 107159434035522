
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.color-box {
  background-color: var(--bg-main);
  height: 100%;
  width: 100%;
}

#root {
  min-height: 99vh;
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  height: 44px;
  gap: 13px;
  background: #0A49A5;
  border-radius: 18px;
  white-space: nowrap;
  // width: 100%;

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    color: #FFFFFF;
  }
}

.button-big-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  height: 44px;
  gap: 13px;
  background: #0A49A5;
  border-radius: 12px;
  white-space: nowrap;
  // width: 100%;

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    color: #FFFFFF;
  }
}

.button-border {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  height: 44px;
  gap: 13px;
  border: 1px solid var(--line-line-light);
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    color: var(--ui-checkbox-bg);
  }
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disabled {
  background: #CFCFD2;
  pointer-events:none;
  span {
    color: #929298;
  }
}

.max-width253 {
  max-width: 253px;
  min-width: 200px;

  @media screen and (max-width: 402px) {
    max-width: 100%;
  }
}

.wraper-report {
  display: flex;
  gap: 8px;
}
.active .button-active-color {
  background-color: #0A49A5;
  color: #FFFFFF;
}

.blur-wrapper-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(177, 177, 181, 0.72);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  z-index: 10;
  height: 100vh;

  &--center {
    align-items: center;
  }
}

.form-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  color: #070708;
}

.fit-content {
  width: fit-content;
}

.flex {
  display: flex;
  align-items: center;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  display: flex;
  align-items: center;
  color: #070708;
}

.absolute {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.arrow-left {
  position: absolute;
  top: 20px;
  left: 25px;
}

// .left .input-item--left-icon {
//   padding-left: 10px;
// }

.table-header .table-header-close-line {
  border-top: none;
  margin-top: -15px;
}

.title-block-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.title-block-flex {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-title-grid {
  justify-self: flex-end;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; // Yeah, yeah everybody write about it
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}


.input-opacity {
  display: block;

  @media screen and (max-width: 991px) {
    display: none
  }
}

.input-opacity-mob {
  display: none;

  @media screen and (max-width: 991px) {
    display: block
  }
}

.big-first-letter {
  text-transform: capitalize;
}

a {
  color:  #0A49A5;
}

.wrapper-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #74747B;
    text-align: center;
    max-width: 265px;
    margin-top: 16px;
    
    span {
      color: #0A49A5;
    }
  }
}

.table-block-custom {
  // height: calc(100% - 170px);
  // min-height: 100%;
  max-height: 100%;
}

.url {
  margin-top: -25px;
  padding-bottom: 16px;
}

.table-body-height {
  height: 100%;
  min-height: 100%;
}

.width-content {
  width: max-content;
}

.table-body-wrap {
  padding: 0 15px;
}

.dropdown {
  display: none;
  position: absolute;
  background: var(--ui-block-bg);
  // border: 1px solid var(--border-color2);
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04);
  border-radius: 12px;
  z-index: 3;

  &--lang {
    width: max-content;
    top: calc(100% + 32px);
    left: 50%;
    transform: translateX(-50%);
  }
  &--header-user-nav {
    min-width: 250px;
    right: 35px;
    top: 0;

    @media screen and (max-width: 991px) {
      right: unset;
      left: 0;
    }

    // ul {
    //   > li + li {
    //     border-top: 1px solid var(--border-color);
    //   }
    // }
  }
}

.dropdown-list {
  border-radius: 12px;
  overflow: auto;
  top: 0;

  > li {
    > a,
    > button {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 8px;
      padding: 12px 16px;
      color: var(--text-secondary-color);
      transition: 0.2s;
      font-weight: 500;
      font-size: 14px;
      &:hover {
        background: var(--background-third);
      }
    }
  }
}

.dropdown-icon {
  display: flex;
  width: 25px;
}

.tr .active .dropdown {
  display: block;
}

.td--right {
  position: relative;
}

.padding {
  display: flex;
  justify-content: flex-end;
  width: 20px;
}

.large-text {
  text-transform: capitalize;
}

.points {
  padding: 0 5px;
}

.position-absolute {
  position: absolute;
  top: -50px;
  right: 25px;
}

.point-padding {
  padding: 0 10px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-wait {
  cursor: wait;
}

.cursor-help {
  cursor: help;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.padding-bottom {
  padding-bottom: 24px;
}

.input-btn-wrap {
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    margin-bottom: -5px;
  }
}

.list-exception {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.exception {
  color: #070708;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
}

.exception-title {
  color: var(--text-primary-light, #070708);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  margin-bottom: 13px;
}

.exception-justify {
  display: flex;
  gap: 5px;
}

.word-break {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.pointer {
  cursor: pointer;
}

.button-style-wrap {
  display: flex;
  gap: 10px;
}

.button-style {
  // padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  border: 1px solid var(--color-button-dark-grey, #CFCFD2);
  width: 48px;
  height: 48px;
  color: #929298;

  svg {
    fill: #CFCFD2
  }
  
  &:hover {
    background: #FFF;
    border: 1px solid var(--color-border-blue, #0A49A5);
    color: #0A49A5;

    svg {
      path { fill: #0A49A5 !important; }
    }
  }
}

.flex-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-content-gap {
  display: flex;
  gap: 16px;
  align-items: center;
}

.popup-position {
  right: 0;
  top: 55px;
  color: var(--text-primary-light, #070708);
  width: 348px;
}

.center {
  text-align: center;
}

.table-header .tr-not-border {
  border-top: none;
}

.input-right {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.margin-top {
  margin-top: 16px;
}

.card-top-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0 32px;

  @media screen and (max-width: 1224px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}


#canvas-container {
  height: 100%;
  width: auto;
}

.wrapper-image {
  img {
    max-width: 60px;
    min-width: 60px;
    width: 100%;
    height: 60px;
    border-radius: 12px;
    overflow: hidden;
  }
}

.link-color {
  color: #7e9fd0 !important;
}

.filter-grid {
  grid-template-columns: 1fr 1fr 1fr;
}

.padding-null {
  padding: 0;
}

.padding-wrapper {
  padding: 15px;
}

.flex-wrap {
  @media screen and (max-width: 1550px) {
    flex-wrap: wrap;
    margin-bottom: 16px;
    gap: 16px;
    //background-color: rgba(10, 73, 165, 0.1);
    //border-radius: 8px;
    //padding: 16px;
    >.tabs-buttons-nav-link {
      border-radius: 8px;
      padding: 10px;
      background-color: #fff;
      &.active {
        background: var(--main-color);
        color: #fff;
      }
      &:first-child.active:after {
        display: none;
      }
    }
  }
}

.accordion-template .input {
  max-width: 100%;
}

.accordion-template .input .input-wrapper {
  max-width: 100%;
}
